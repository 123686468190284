import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'components/Layout';
import { TipCard } from 'components/Cards';
import { Permission } from 'components/User';
import { IconList } from 'components/Text';
import { IconProps } from 'components/Images/Icon';
import { DocumentTemplateId, Role } from '../../types/resources';

interface ModalTipProps {
  tipOnly?: boolean;
  type?: DocumentTemplateId;
  maxFiles?: number;
  maxSize?: number;
}

const DEFAULT_MAX_SIZE = 10 * 1000 * 1000; // 10 Mo
const DEFAULT_MAX_FILES = 20;

const ModalTip: FC<ModalTipProps> = ({
  tipOnly,
  children,
  type,
  maxFiles = DEFAULT_MAX_FILES,
  maxSize = DEFAULT_MAX_SIZE,
}) => {
  const { t } = useTranslation();
  const items = [
    {
      icon: 'Document' as IconProps['name'],
      content: t('upload.advice.finger'),
    },
    { icon: 'Show' as IconProps['name'], content: t('upload.advice.net') },
    {
      icon: 'Scan' as IconProps['name'],
      content: t('upload.advice.readable'),
    },
    {
      icon: 'Document' as IconProps['name'],
      content: t('upload.advice.quantity', { number: maxFiles }),
    },
    {
      icon: 'Document' as IconProps['name'],
      content: t('upload.advice.size', { number: maxSize / 1000 / 1000 }),
    },
  ];
  return (
    <Flex
      direction={{ xs: 'column' }}
      paddingRight={{ xs: 'space24' }}
      paddingTop={{ xs: 'space32' }}
      paddingLeft={{ xs: 'space24' }}
      paddingBottom={{ xs: 'space32' }}
      width="348px"
    >
      <Permission roles={[Role.CUSTOMER]}>
        {t(`upload.tips.${type}`, { defaultValue: '' }) && (
          <TipCard
            title={t('upload.tips.title')}
            type="info"
            content={t(`upload.tips.${type}`)}
          />
        )}
      </Permission>
      {!tipOnly && <IconList title={t('upload.advice.title')} items={items} />}
      {children}
    </Flex>
  );
};

export default ModalTip;
