import { Flex } from 'components/Layout';
import { FC } from 'react';
import NoteCard, {
  DeleteNoteProps,
  UpdateNoteProps,
} from 'components/Notes/NoteCard';
import NoteStepper from 'components/Notes/NoteStepper';
import { useAuth } from '../../../hooks/useAuth';
import { CommentFull, Role } from '../../../types/resources';

interface NotesListProps {
  notes: CommentFull[];
  onUpdate: (variables: UpdateNoteProps) => void;
  onDelete: (variables: DeleteNoteProps) => void;
}

const NotesList: FC<NotesListProps> = ({ notes, onUpdate, onDelete }) => {
  const { user } = useAuth();

  return (
    <Flex direction={{ xs: 'column' }}>
      {notes?.map((note, index) => {
        const { id, created_by, content, processed, created_at } = note;

        const date = new Date(created_at);

        return (
          <Flex
            key={id}
            marginBottom={{
              xs: index === notes.length - 1 ? 'none' : 'space24',
            }}
          >
            <NoteStepper
              date={date}
              isLastItem={index === notes.length - 1}
              processed={Boolean(processed)}
            />
            <NoteCard
              id={id}
              fullname={created_by?.full_name}
              content={content}
              processed={Boolean(processed)}
              onUpdate={onUpdate}
              onDelete={onDelete}
              icons={{
                showEdit:
                  user?.id === created_by?.id || user?.role === Role.ADMIN,
                showDelete: user?.role === Role.ADMIN,
                showValid: true,
              }}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default NotesList;
